<template>
  <div>
    <v-data-table
      class="mappings-selection-table"
      ref="mappingsTable"
      :headers="headers"
      :items="items"
      disable-sort
      hide-default-footer
      :server-items-length="items.length"
      :fixed-header="!!tableHeight"
      :height="tableHeight"
    >
      <template v-for="(header, index) in headers" v-slot:[`header.${index}`]>
        <div :key="index">
          <v-autocomplete
            :label="
              $localizationService.localize(
                'drp_import.stepper.step.mapping.table.header_label'
              )
            "
            item-text="name"
            clearable
            :disabled="
              pLoading ||
              !pSelectedModelMapping ||
              (!selectedHeaders[index] &&
                headerAutocompleteItems.every((it) => it.disabled))
            "
            :items="headerAutocompleteItems"
            v-model="selectedHeaders[index]"
            return-object
            @change="emitUpdatedMappings()"
          >
            <template v-slot:selection="data">
              {{ getReportModelPropertyDisplayText(data.item.name) }}
            </template>
            <template v-slot:item="data">
              {{ getReportModelPropertyDisplayText(data.item.name) }}
            </template>
          </v-autocomplete>
        </div>
      </template>
      <template
        v-for="(header, index) in headers"
        v-slot:[`item.${index}`]="{ item }"
      >
        <span :key="header.value">
          {{ item[index] }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import { IS_MOBILE } from "../../../utils/utils";

const locService = Vue.prototype.$localizationService;

export default {
  name: "MappingsSelectionTable",

  props: {
    pLoading: Boolean,
    pParsedModelItems: Array,
    pReportModelProperties: Array,
    pSelectedModelMapping: Object,
  },

  data: () => ({
    selectedHeaders: [],
  }),

  computed: {
    tableHeight() {
      const height = IS_MOBILE ? 300 : 500;
      // TODO: refactor
      //* default table row height
      return this.items.length * 50 > height ? height : undefined;
    },

    items() {
      return (this.pParsedModelItems || []).map((items) => ({ ...items }));
    },

    headers() {
      return this.pParsedModelItems && this.pParsedModelItems.length
        ? this.pParsedModelItems[0].map((item, index) => ({ value: index }))
        : [];
    },

    headerAutocompleteItems() {
      return this.pSelectedModelMapping
        ? (this.pReportModelProperties || []).map((it) => ({
          name: it.name,
          disabled: this.selectedHeaders.some(
            (sHeader) => sHeader && it.name == sHeader.name,
          ),
        }))
        : [];
    },
  },

  methods: {
    getReportModelPropertyDisplayText(text) {
      switch (text) {
        case "name":
          return locService.localize(
            "report.amazon_logistics_import_report.item.default.property.name",
          );
        case "route":
          return locService.localize(
            "report.amazon_logistics_import_report.item.default.property.route",
          );
        case "staging":
          return locService.localize(
            "report.route_staging_import_report.item.default.property.staging",
          );
        default:
          return "";
      }
    },

    emitUpdatedMappings() {
      const mappings = {};

      this.selectedHeaders.forEach((it, index) => {
        if (it) {
          mappings[it.name] = index;
        }
      });

      this.$emit("onUpdateMappings", mappings);
      this.emitMappingState();
    },

    emitMappingState() {
      this.$emit("onUpdateSelectedMappingState");
    },

    createHeadersByOriginalMappings() {
      if (
        (this.pSelectedModelMapping || {}).mappings
        && Object.keys(this.pSelectedModelMapping.mappings).length
      ) {
        const selectedHeaders = [];

        Object.entries(this.pSelectedModelMapping.mappings).forEach((it) => {
          selectedHeaders[it[1]] = {
            name: it[0],
          };
        });

        this.selectedHeaders = selectedHeaders;
      } else {
        this.selectedHeaders = [];
      }

      setTimeout(() => this.emitMappingState());
    },
  },
};
</script>

<style lang="scss">
.dark-local-user-theme {
  .mappings-selection-table {
    background: var(--v-grafanaOverlayColor-lighten2) !important;

    th,
    td {
      border-right: thin solid rgba(255, 255, 255, 0.12);
      border-left: thin solid rgba(255, 255, 255, 0.12);

      &:first-child,
      &:last-child {
        border: none;
      }
    }
    .v-data-table-header {
      th {
        background: var(--v-tableToolbarBackground-base) !important;
      }
    }
  }
}
.light-local-user-theme {
  .mappings-selection-table {
    background: var(--v-grafanaOverlayColor-base) !important;

    th,
    td {
      border-right: thin solid rgba(0, 0, 0, 0.12);
      border-left: thin solid rgba(0, 0, 0, 0.12);

      &:first-child,
      &:last-child {
        border: none;
      }
    }
    .v-data-table-header {
      th {
        background: var(--v-secondary-base) !important;
      }
    }
  }
}
</style>
