var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"mappingsTable",staticClass:"mappings-selection-table",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","hide-default-footer":"","server-items-length":_vm.items.length,"fixed-header":!!_vm.tableHeight,"height":_vm.tableHeight},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,index){return {key:("header." + index),fn:function(){return [_c('div',{key:index},[_c('v-autocomplete',{attrs:{"label":_vm.$localizationService.localize(
              'drp_import.stepper.step.mapping.table.header_label'
            ),"item-text":"name","clearable":"","disabled":_vm.pLoading ||
            !_vm.pSelectedModelMapping ||
            (!_vm.selectedHeaders[index] &&
              _vm.headerAutocompleteItems.every(function (it) { return it.disabled; })),"items":_vm.headerAutocompleteItems,"return-object":""},on:{"change":function($event){return _vm.emitUpdatedMappings()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getReportModelPropertyDisplayText(data.item.name))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getReportModelPropertyDisplayText(data.item.name))+" ")]}}],null,true),model:{value:(_vm.selectedHeaders[index]),callback:function ($$v) {_vm.$set(_vm.selectedHeaders, index, $$v)},expression:"selectedHeaders[index]"}})],1)]},proxy:true}}),_vm._l((_vm.headers),function(header,index){return {key:("item." + index),fn:function(ref){
              var item = ref.item;
return [_c('span',{key:header.value},[_vm._v(" "+_vm._s(item[index])+" ")])]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }